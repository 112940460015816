import React, { useState, useEffect } from 'react';
import HeaderBar from '../../../components/header-bar';
import InputTextField from '../../../components/input-text';
import AddressSelector from '../../../components/address-selector';
import BottomSheet from '../../../components/bottom-sheet';
import Button from '../../../components/button';
import { useAPI } from '../../../utils/api';
import { showLoading, dismissLoading } from '../../../utils/loading';
import WebFn, { callAppFunc } from '../../../utils/jsbridge/index.js';

// mode
// add:  新增
// edit: 編輯
const TaxManage = ({ location, mode }) => {
  const api = useAPI();
  const [uniformNumbers, setUniformNumbers] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyVIPnumber, setCompanyVIPnumber] = useState('');
  const [ID, setID] = useState('');
  const [defaultCheck, setDefaultCheck] = useState(false);
  const [update, setUpdate] = useState(false);

  const validData = (param) => {
    let isValid = true;
    if (param.companyName === '') {
      alert('請輸入公司名稱');
      isValid = false;
      return isValid;
    }

    if (param.uniformNumbers === '') {
      alert('請輸入公司統編');
      isValid = false;
      return isValid;
    }

    // if (param.companyVIPnumber === '') {
    //   alert('請輸入卡號');
    //   isValid = false;
    //   return isValid;
    // }

    return isValid;
  };

  const newTax = (param) => {
    showLoading();
    api
      .newTax(param)
      .then((res) => {
        dismissLoading();
        alert('新增成功');
        callAppFunc('backHome', {});
      })
      .catch((error) => {
        dismissLoading();
        alert(error);
        console.log(error);
      });
  };

  const updateTax = (param) => {
    showLoading();
    api
      .updateTax(param)
      .then((res) => {
        dismissLoading();
        alert('更新成功');
        callAppFunc('backHome', {});
      })
      .catch((error) => {
        dismissLoading();
        console.log(error);
      });
  };

  const submit = (event) => {
    event.preventDefault();

    console.log('event', event);
    const uniformNumbers = event.target[0].value;
    const companyName = event.target[1].value;
    const companyVIPnumber = event.target[2].value;
    const isDefault = event.target[3].checked;

    if (
      !validData({ uniformNumbers, companyName, companyVIPnumber, isDefault })
    ) {
      return false;
    }

    if (mode === 'add') {
      newTax({ uniformNumbers, companyName, companyVIPnumber, isDefault });
    } else {
      console.log('update tax', {
        uniformNumbers,
        companyName,
        companyVIPnumber,
        isDefault,
        ID,
      });
      updateTax({
        uniformNumbers,
        companyName,
        companyVIPnumber,
        isDefault,
        ID,
      });
    }
  };

  useEffect(() => {
    if (mode !== 'add') {
      console.log(location.state.data);
      setUniformNumbers(location.state.data.data.uniformNumbers);
      setCompanyName(location.state.data.data.companyName);
      setCompanyVIPnumber(location.state.data.data.companyVIPnumber);
      setDefaultCheck(location.state.data.data.isDefault);
      setID(location.state.data.data.ID);
      setUpdate(true);
    } else {
      setUpdate(true);
    }
  }, []);

  return (
    <>
      <HeaderBar
        title={`統一編號${mode === 'add' ? '新增' : '編輯'}`}
        goBack={() => {
          callAppFunc('backHome', {});
        }}
      />
      {update ? (
        <form onSubmit={submit}>
          <div className="tax-manage-page">
            <InputTextField
              label="公司統一編號"
              placeholder="統一編號"
              marginBottom={12}
              mLength={8}
              type="text"
              text={uniformNumbers}
            />
            <InputTextField
              label="公司名稱"
              placeholder="公司全名"
              marginBottom={12}
              text={companyName}
            />
            <InputTextField
              label="卡號"
              placeholder="卡號"
              marginBottom={24}
              type="number"
              text={companyVIPnumber}
            />
            <label className="checkbox set-default">
              <input
                type="checkbox"
                name="1"
                value="1"
                checked={defaultCheck}
                onChange={() => {
                  if (defaultCheck) {
                    setDefaultCheck(false);
                  } else {
                    setDefaultCheck(true);
                  }
                }}
              />
              <span>設爲預設</span>
            </label>
          </div>

          <BottomSheet persistant noTitle noRounded>
            <Button
              text={`${mode === 'add' ? '新增' : '修改'}`}
              type="submit"
            />
          </BottomSheet>
        </form>
      ) : undefined}
      <style jsx>
        {`
          .tax-manage-page {
            padding: 26px 20px 126px 20px;

            .set-default {
              margin-bottom: 22px;
            }
          }
        `}
      </style>
    </>
  );
};

export default TaxManage;
